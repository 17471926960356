<template>
    <TheContentHeader titlePage="Lista de Usuarios" />
    <the-content-body>
        <widget-card title="Usuarios" classHeader="bg-primary">
            <template v-slot:header>
                <div class="d-flex justify-content-between">
                    <div class="text-white">Usuarios</div>
                    <base-button-fab @on:click="openModal('newUser')" :permissionId="4"></base-button-fab>
                </div>
            </template>
            <template v-slot:body>
                <widget-alerts v-if="alert.showAlert" :messages="alert.messageServer"
                    :typeAlert="alert.typeAlert"></widget-alerts>
                <base-input-group :icon="'fa-solid fa-magnifying-glass'" v-model="formData.search"
                    :onBlur="callLoadUsers"></base-input-group>
                <widget-loading v-if="isLoading" />
                <template v-else-if="userLists.data.length > 0">
                    <widget-select-paginator v-model="formData.numPages"
                        :onChange="callLoadUsers"></widget-select-paginator>
                    <base-table-forms :data="userLists" :dataFunction="callLoadUsers" :headers="headers"
                        :orderColumn="formData.column" :order="formData.order" :whitOrder="true">
                        <tr v-for="user in userLists.data" :key="user.id">
                            <td class="text-center">{{ user.idUsuario }}</td>
                            <td class="text-center">{{ user.nombreUsuario }}</td>
                            <td class="text-center">{{ user.email }}</td>
                            <td class="text-center">{{ user.Estado }}</td>
                            <td class="text-center">{{ user.descripcionPerfil }}</td>
                            <td class="text-center">
                                <widget-dropdown icon="fa-solid fa-user-pen text-primary" :dropdownItem="[
                        { label: 'Editar', action: () => { openModal(user.idUsuario.toString()) }, permissionId: 5, visible: true },
                        { label: 'Perfil', action: () => { $router.push({name:'profile',params:{id:user.idUsuario.toString()}}) }, permissionId: 8, visible: true },
                        { label: user.Estado === 'A' ? 'Bloquear' : 'Desbloquear', action: () => { changeUserStatus(user.idUsuario) }, permissionId: 7, visible: true }
                    ]"></widget-dropdown>
                            </td>
                        </tr>
                    </base-table-forms>
                </template>
                <div v-else>
                    <div class="row justify-content-md-center">
                        <div class="col-12 alert-info text-center mt-5">
                            No hay Información a mostrar
                        </div>
                    </div>
                </div>
            </template>
        </widget-card>
    </the-content-body>
    <widget-modal-boostrap modalId="modalUser" :modalTitle="modalTitle" modalClassSize="modal-lg" :showFooter="false"
        modalStatic="static" >
        <template v-slot:modalBody>
            <UserForm @on:createdUser="openModal" @on:closeModal="closeModal" :userId="userId"></UserForm>
        </template>
    </widget-modal-boostrap>
</template>

<script>
import { onMounted, ref, watch, computed } from 'vue';
import { Modal } from 'bootstrap';

import useUsers from '@/modules/users/composables/useUsers'
import { useStore } from 'vuex'

import UserForm from '../components/UserForm.vue';

import WidgetAlerts from '@/common/widgets/WidgetAlerts.vue';
import BaseButtonFab from '@/common/forms/BaseButtonFab.vue';
import WidgetCard from '@/common/widgets/WidgetCard.vue';
import TheContentBody from '@/modules/dashboard/components/TheContentBody.vue';
import TheContentHeader from '@/modules/dashboard/components/TheContentHeader.vue';
import WidgetDropdown from '@/common/widgets/WidgetDropdown.vue';
import WidgetLoading from '@/common/widgets/WidgetLoading.vue';
import WidgetModalBoostrap from '@/common/widgets/WidgetModalBoostrap.vue';
import WidgetSelectPaginator from '@/common/widgets/WidgetSelectPaginator.vue';
import BaseTableForms from '@/common/forms/BaseTableForms.vue';
import BaseInputGroup from '@/common/forms/BaseInputGroup.vue';


export default {
    components: {
        WidgetAlerts,
        BaseButtonFab,
        WidgetCard,
        TheContentBody,
        TheContentHeader,
        WidgetDropdown,
        WidgetLoading,
        WidgetModalBoostrap,
        WidgetSelectPaginator,
        BaseTableForms,
        UserForm,
        BaseInputGroup

    },
    setup() {

        const formData = ref({
            search: '',
            numPages: '5',
            page: 1,
            column: 'idUsuario',
            order: 'asc'

        })

        const headers = ref([
            { encabezado: 'ID', columna: 'idUsuario' },
            { encabezado: 'Nombre', columna: 'nombreUsuario' },
            { encabezado: 'Email', columna: 'email' },
            { encabezado: 'Estado', columna: 'Estado' },
            { encabezado: 'Rol', columna: 'perfil' },
            { encabezado: 'Acciones', columna: null },
        ])
        const {
            userLists,
            changeUserStatus,
            loadUsers,
            isLoading,
            modals,
            alert,
            userId,
            getUserShops,
            getUser,
        } = useUsers()
        const store = useStore()

        onMounted(() => {
            modals.modalUser = new Modal('#modalUser', {})
        })
        const callLoadUsers = (page, column) => {
            formData.value.page = page

            if (column) {
                formData.value.column = column
                formData.value.order = formData.value.order == 'asc' ? 'desc' : 'asc'
            }
            loadUsers(formData.value)
        }
        callLoadUsers()


        return {
            isLoading,
            formData,
            userLists,
            userId,
            alert,
            headers,

            callLoadUsers,
            openModal: async (id) => {
                store.commit('users/clearUser')
                modals.modalUser.show()
                userId.value = id
                if (id != 'newUser') {
                    await getUser(id)
                    await getUserShops(id)
                }
            },
            changeUserStatus,
            closeModal: () => {
                modals.modalUser.hide()
            },

            modalTitle: computed(() => userId.value === 'newUser' ? 'Crear usuario' : 'Actualizar Usuario No. ' + userId.value)

        }

    },
}
</script>