<template>
    <div class="wrapper-stepper">
        <div v-if="!isLoadingRequest" class="stepper">
            <!-- Barra de progreso -->
            <div class="stepper-progress d-none d-sm-block d-md-block d-lg-block">
                <div class="stepper-progress-bar" :style="'width:' + stepperProgress"></div>
            </div>
            <!-- Pasos -->
            <div class="stepper-item d-none d-sm-block d-md-block d-lg-block" role="button"
                :class="{ 'current': step == item, 'success': step > item }" v-for="item in countItems - 1" :key="item"
                @click="step = item">
                <div class="stepper-item-counter">
                    <img class="icon-success"
                        src="https://www.seekpng.com/png/full/1-10353_check-mark-green-png-green-check-mark-svg.png" alt="">
                    <span class="number">
                        {{ item }}
                    </span>
                </div>
                <span class="stepper-item-title">
                    {{ stepName[`step${item}`] }}
                </span>
            </div>
        </div>

        <!-- Contenido -->
        <form @submit.prevent="onSubmit">
            <widget-alerts v-if="showAlert" :messages="messageServer" :typeAlert="typeAlert"></widget-alerts>
            <!-- Mostrar Motivo devolución -->
            <div class="text-danger">{{ motivoDevolucion }}</div>
            <div class="stepper-content" v-for="item in countItems" :key="item">
                <div class="stepper-pane" v-if="step == item">
                    <credit-request-basic v-if="step == 1"></credit-request-basic>
                    <credit-request-localization v-if="step == 2"></credit-request-localization>
                    <credit-request-financial v-if="step == 3"></credit-request-financial>
                    <credit-request-income v-if="step == 4"></credit-request-income>
                    <credit-request-references v-if="step == 5"></credit-request-references>
                    <credit-request-info v-if="step == 6"></credit-request-info>
                    <files-list v-if="step == 7" tipoModelo="4" :codModelo="Ca111CodSolicitud" tipoAliado="0"
                        :ocupacionCliente="Ca111CodOcupacion" estudioCategoria="0"></files-list>
                    <credit-request-send v-if="step == 8"></credit-request-send>
                    <!-- Estas en el paso <span class="tx-green-1">{{ item }}</span> aqui mostrarás el contenido :D -->
                </div>
            </div>
            <!-- Botones -->
            <div class="controls">
                <button class="btn" @click="step--" type="button" :disabled="step == 1 || isLoadingOperationRequest">
                    Anterior
                </button>
                <button class="btn btn--green-1" :disabled="step == countItems || isLoadingOperationRequest">
                    {{ isLoadingOperationRequest ? 'Procesando' : 'Siguiente' }}
                </button>
            </div>
        </form>
    </div>
</template>

<script>
import { computed, ref, toRef, watch } from 'vue'
import { useStore } from 'vuex'
import Swal from 'sweetalert2'

import CreditRequestBasic from '@/modules/credit-request/components/CreditRequestBasic.vue'
import CreditRequestFinancial from '@/modules/credit-request/components/CreditRequestFinancial.vue'
import CreditRequestLocalization from '@/modules/credit-request/components/CreditRequestLocalization.vue'
import CreditRequestIncome from '@/modules/credit-request/components/CreditRequestIncome.vue'
import CreditRequestReferences from '@/modules/credit-request/components/CreditRequestReferences.vue'
import CreditRequestInfo from '@/modules/credit-request/components/CreditRequestInfo.vue'
import FilesList from '@/modules/files/views/FilesList.vue'
import CreditRequestSend from '@/modules/credit-request/components/CreditRequestSend.vue'
import WidgetAlerts from '@/common/widgets/WidgetAlerts.vue';


import useRequest from '@/modules/credit-request/composables/useRequest';



export default {
    props: {
        countItems: {
            type: Number,
            default: 4
        },
        requestId: {
            type: String,
        }
    },
    components: {
        CreditRequestBasic,
        CreditRequestFinancial,
        CreditRequestLocalization,
        CreditRequestIncome,
        CreditRequestReferences,
        CreditRequestInfo,
        FilesList,
        CreditRequestSend,
        WidgetAlerts

    },

    setup(props, { emit }) {
        const store = useStore()
        const step = ref(1)
        const requestIdCopy = toRef(props, 'requestId')
        const stepName = {
            step1: 'Datos básios',
            step2: 'Localización',
            step3: 'Finanzas',
            step4: 'Fuente Ingresos',
            step5: 'Referencias',
            step6: 'Crédito',
            step7: 'Imágenes',
            step8: 'A estudio',
        }

        const {
            createRequest,
            getRequest,
            isLoadingOperationRequest,
            isLoadingRequest,
            messageServer,
            request,
            showAlert,
            typeAlert,
            updateRequest,
        } = useRequest()

        const isRequestEditing = computed(() => requestIdCopy.value === 'newRequest' ? false : true)
        watch(requestIdCopy, async (newValue) => {
            step.value = 1
            store.commit('request/clearRequest')
            if (isRequestEditing.value) {
                const { data } = await getRequest(requestIdCopy.value)

            }
        })
        return {
            step,
            messageServer,
            showAlert,
            typeAlert,
            isLoadingOperationRequest,
            isRequestEditing,
            isLoadingRequest,
            request,
            stepName,

            motivoDevolucion: computed(() => {

                if (isRequestEditing.value) {
                    return request.value.Ca111MotivoDevolucionSolicitudFase1 != '' ? 'Motivo devolución' + request.value.Ca111MotivoDevolucionSolicitudFase1 : ''
                }
                return ''

            }),


            Ca111CodOcupacion: computed(() => store.state['request']['request']['Ca111CodOcupacion']),
            Ca111CodSolicitud: computed(() => store.state['request']['request']['Ca111CodSolicitud']),


            stepperProgress: computed(() => {
                return (100 / props.countItems - 1) * (step.value - 1) + '%'
            }),


            onSubmit: async () => {

                if (!isRequestEditing.value) {
                    const { response, data } = await createRequest(store.state['request']['request'])
                    if (response) {
                        emit('on:createdRequest', data.solicitud.Ca111CodSolicitud)
                        step.value++
                    }
                    return
                }
                const { response } = await updateRequest(store.state['request']['request'], store.state['request']['request'].Ca111CodSolicitud, step.value)

                if (response) {
                    if (step.value === 8) {
                        emit('on:loadRequestList')
                        emit('on:closeModal')
                        return
                    }
                    step.value++
                }

            }
        }

    },
}
</script>

<style lang="scss" scoped>
$default : #C5C5C5;
$green-1 : #75CC65;
$transiton : all 500ms ease;

body {
    background-image: linear-gradient(60deg, #abecd6 0%, #fbed96 100%);
    color: #ffffff;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: sans-serif;
}

.tx-green-1 {
    color: $green-1;
    font-weight: 600;
}

.wrapper-stepper {
    background-color: #fff;
    // padding: 60px;
    padding: 0px 20px 0px 20px;
    border-radius: 32px;
    box-shadow: rgba($color: #000000, $alpha: 0.09);
    // display: flex;
    // flex-direction: column;
    // justify-content: space-between;
}

.stepper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // width: 660px;
    // widows: auto;
    position: relative;
    z-index: 0;
    margin-bottom: 24px;

    &-progress {
        position: absolute;
        background-color: $default;
        height: 2px;
        z-index: -1;
        left: 0;
        right: 0;
        margin: 0 auto;

        &-bar {
            position: absolute;
            left: 0;
            height: 100%;
            width: 0%;
            background-color: $green-1;
            transition: $transiton;
        }
    }
}

.stepper-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $default;
    transition: $transiton;

    &-counter {
        height: 68px;
        width: 68px;
        display: grid;
        place-items: center;
        background-color: #fff;
        border-radius: 100%;
        border: 2px solid $default;
        position: relative;

        .icon-success {
            position: absolute;
            opacity: 0;
            transform: scale(0);
            width: 24px;
            transition: $transiton;
        }

        .number {
            font-size: 22px;
            transition: $transiton;
        }
    }

    &-title {
        position: absolute;
        font-size: 14px;
        bottom: -24px;
    }
}

.stepper-item.success {
    .stepper-item-counter {
        border-color: $green-1;
        background-color: #c8ebc1;
        color: #fff;
        font-weight: 600;

        .icon-success {
            opacity: 1;
            transform: scale(1);
        }

        .number {
            opacity: 0;
            transform: scale(0);
        }
    }

    .stepper-item-title {
        color: $green-1;
    }
}

.stepper-item.current {
    .stepper-item-counter {
        border-color: $green-1;
        background-color: $green-1;
        color: #fff;
        font-weight: 600;
    }

    .stepper-item-title {
        color: #818181;
    }
}

.stepper-pane {
    color: #333;
    text-align: center;
    padding: 6px 60px;
    // padding: 120px 60px;
    box-shadow: 0 8px 12px rgba($color: #000000, $alpha: 0.09);
    margin: 40px 0;
}

.controls {
    display: flex;
}

.btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 16px;
    border: 1px solid;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    line-height: 1.5;
    transition: all 150ms;
    border-radius: 4px;
    width: fit-content;
    font-size: 0.75rem;
    color: #333;
    background-color: #f0f0f0;
    border-color: #f0f0f0;

    &:disabled {
        opacity: 0.5;
        pointer-events: none;
    }

    &--green-1 {
        background-color: $green-1;
        border-color: $green-1;
        color: #fff;
        margin-left: auto;
    }
}
</style>
