import { createRouter, createWebHistory } from 'vue-router'

import authRouter from '../modules/auth/router'
import dashboardRouter from '../modules/dashboard/router'
import usersRouter from '../modules/users/router'
import parametrizationRouter from '../modules/parameterization/router'
import errorsRouter from '../modules/errors/router'
import creditRequestRouter from '../modules/credit-request/router'
import creditStudyRouter from '../modules/credit-study/router'
import alliedRouter from '../modules/allied/router'
import shopRouter from '../modules/shop/router'
import productRouter from '../modules/product/router'
import chatRouter from '../modules/chat/router'
import casherRouter from '../modules/casher/router'
import ticketRouter from '../modules/casher/router-ticket'
import rolesRouter from '../modules/roles/router'
import notificationsRouter from '../modules/notification/router'
import tasksRouter from '../modules/tasks/router'
import reports from '../modules/reports/router'
import profile from '../modules/profile/router'

const routes = [
    {
        path: '/login',
        meta: {
            title: 'Auth',
            icon: 'fas fa-home',
            showNav: false,
            permission: 0,
            group: 'standar',
        },
        ...authRouter
    },
    {
        path: '/dashboard',
        meta: {
            title: 'Inicio',
            icon: 'fas fa-home',
            showNav: true,
            permission: 2,
            group: 'standar',
        },

        ...dashboardRouter
    },
    {
        path: '/product',
        meta: {
            title: 'Productos',
            icon: 'fa-brands fa-shopify',
            showNav: true,
            permission: 64,
            group: 'standar',
        },
        ...productRouter
    },
    {
        path: '/casher',
        meta: {
            title: 'Caja',
            icon: 'fa-solid fa-cash-register',
            showNav: true,
            permission: 46,
            group: 'standar',
        },
        ...casherRouter
    },
    {
        path: '/users',
        meta: {
            title: 'Usuarios',
            icon: 'fas fa-users',
            showNav: true,
            permission: 3,
            group: 'standar',
        },
        ...usersRouter
    },
    {
        path: '/allied',
        meta: {
            title: 'Aliado',
            icon: 'fas fa-user-plus',
            showNav: true,
            permission: 26,
            group: 'standar',
        },
        ...alliedRouter
    },
    {
        path: '/shop',
        meta: {
            title: 'Almacén',
            icon: 'fa-solid fa-shop',
            showNav: true,
            permission: 29,
            group: 'standar',
        },
        ...shopRouter
    },
    {
        path: '/credit-request',
        meta: {
            title: 'Solicitud de Crédito',
            icon: 'fas fa-credit-card',
            showNav: true,
            permission: 36,
            group: 'standar',
        },
        ...creditRequestRouter
    },
    {
        path: '/credit-study',
        meta: {
            title: 'Estudio de crédito',
            icon: 'fa-solid fa-clipboard-check',
            showNav: true,
            permission: 41,
            group: 'standar',
        },
        ...creditStudyRouter
    },
    {
        path: '/reports',
        meta: {
            title: 'Reportes',
            icon: 'fa-solid fa-file-excel',
            showNav: true,
            permission: 124,
            group: 'standar',
        },
        ...reports
    },
    {
        path: '/profiles',
        meta: {
            title: 'Perfil',
            icon: 'fas fa-user',
            showNav: true,
            permission: 8,
            group: 'acount',
        },
        ...profile
    },
    {
        path: '/chat',
        meta: {
            title: 'Chat',
            icon: 'fa-solid fa-comments',
            showNav: true,
            permission: 61,
            group: 'acount',
        },
        ...chatRouter
    },
    {
        path: '/tasks',
        meta: {
            title: 'Tareas',
            icon: 'fa-solid fa-tasks',
            showNav: true,
            permission: 116,
            group: 'standar',
        },
        ...tasksRouter
    },
    {
        path: '/parametrization',
        meta: {
            title: 'Parametrizacion',
            icon: 'fa-solid fa-gear',
            showNav: true,
            permission: 16,
            group: 'admin',
        },
        ...parametrizationRouter
    },
    {
        path: '/roles',
        meta: {
            title: 'Roles y permisos',
            icon: 'fas fa-users-cog fa-fw',
            showNav: true,
            permission: 13,
            group: 'admin',
        },
        ...rolesRouter
    },



    {
        path: '/ticket',
        meta: {
            title: 'Tickets',
            icon: 'fa-solid fa-cash-register',
            showNav: false,
            permission: 64,
            group: 'admin',
        },
        ...ticketRouter
    },
    {
        path: '/notifications',
        meta: {
            title: 'Notificaciones',
            icon: 'fa-solid fa-bell',
            showNav: true,
            permission: 0,
            group: 'acount',
        },
        ...notificationsRouter
    },
    {
        path: '/errors',
        meta: {
            title: 'Errores',
            icon: 'fa-solid fa-cash-register',
            showNav: false,
            permission: 0,
            group: 'acount',
        },
        ...errorsRouter
    }

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router