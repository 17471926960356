<template>
    <!-- Navbar -->
    <nav class="main-header navbar navbar-expand navbar-white navbar-light">
        <!-- Left navbar links -->
        <!-- Nombre Aliado/Almacen -->
        <ul class="navbar-nav">
            <li class="nav-item">
                <a class="nav-link" data-widget="pushmenu" @click="showSidebar" href="#" role="button"><i
                        class="fas fa-bars"></i></a>
            </li>
            <template v-if="checkGroupUser('usuario-almacen', userDataLoggedIn.perfil)">
                <li class="nav-item d-none d-xl-block">
                    <a href="#" class="nav-link text-bold">{{ userDataLoggedIn.nombreAliado }}</a>
                </li>
                <li class="nav-item d-none d-xl-block">
                    <a href="#" class="nav-link text-bold">Cartera: {{ userDataLoggedIn.nombreAlmacen }}</a>
                </li>
            </template>
        </ul>

        <!-- Right navbar links -->
        <ul class="navbar-nav ml-auto">
            <!-- fecha Sistema -->
            <li class="nav-item">
                <a href="#" class="nav-link d-none d-xl-block">Fecha: {{ inputDate(system.fechaSistema, 4)
                }}</a>
                <!-- <div class="d-block d-sm-none ">{{ $fechaSistema->format('M-d') }}</div>
                    <div hidden id="fechaSistema">{{ $fechaSistema }}</div> -->
            </li>
            <!-- Turnos -->
            <template v-if="checkGroupUser('usuario-analista', userDataLoggedIn.perfil)">
                <widget-spinner v-if="isLoading" :typeSpinner="1"></widget-spinner>
                <li v-else class="nav-item dropdown">
                    <a class="nav-link" data-toggle="dropdown" href="#">
                        {{ userParameters.turnos[userDataLoggedIn.Turno][0] }} <i
                            :class=userParameters.turnos[userDataLoggedIn.Turno][2]></i>
                    </a>
                    <div class="dropdown-menu dropdown-menu-xs dropdown-menu-right" style="left: inherit; right: 0px;">
                        <a v-for="turno in userParameters.turnos[userDataLoggedIn.Turno][3]" :key="turno"
                            @click="onChangeAnalystShift(turno)" href="#" class="dropdown-item">
                            <i :class="userParameters.turnos[turno][2]"></i>{{ userParameters.turnos[turno][1] }}
                        </a>

                    </div>
                </li>
            </template>
            <!-- Messages Dropdown Menu -->
            <!-- <the-messages-dropdown v-if="checkPermission(60)"></the-messages-dropdown> -->
            <!-- Notifications Dropdown Menu -->
            <the-notifications-dropdown-menu></the-notifications-dropdown-menu>
            <!-- Fullscreen -->
            <li class="nav-item">
                <a class="nav-link" data-widget="fullscreen" href="#" role="button">
                    <i class="fas fa-expand-arrows-alt"></i>
                </a>
            </li>
            <!-- Active users. Sidebar Rigth -->
            <li class="nav-item">
                <a class="nav-link" data-widget="control-sidebar" data-slide="true" href="#" role="button">
                    <i class="fas fa-users"></i>
                </a>
            </li>

            <!-- User Menu -->
            <TheUserMenu />
        </ul>
    </nav>
    <!-- /.navbar -->
</template>
<script>

import inputFormat from '@/helpers/inputMaskFormat'
import TheMessagesDropdown from './TheMessagesDropdown.vue'
import TheUserMenu from '@/modules/dashboard/components/TheUserMenu.vue'
import WidgetSpinner from '@/common/widgets/WidgetSpinner.vue'
import TheNotificationsDropdownMenu from './TheNotificationsDropdownMenu.vue'

import { useRouter, useRoute } from 'vue-router';
import useAuth from '@/modules/auth/composables/useAuth'
import useDashboard from '@/modules/dashboard/composables/useDashboard'



export default {
    components: {
        TheUserMenu,
        TheMessagesDropdown,
        WidgetSpinner,
        TheNotificationsDropdownMenu
    },

    setup() {
        const router = useRouter()
        const route = useRoute()

        const {
            userDataLoggedIn,
            checkGroupUser,
            checkPermission,
            system,
            userParameters,
            changeAnalystShift,
            isLoading,
        } = useAuth()

      

        const { showSidebar } = useDashboard()

        const { inputDate } = inputFormat()



        return {
            userDataLoggedIn,
            checkGroupUser,
            checkPermission,
            system,
            inputDate,
            userParameters,
            isLoading,

            showSidebar,

            onChangeAnalystShift: async (codShift) => {
                const { response } = await changeAnalystShift(codShift)
                if (response) {
                    router.push({ name: 'start' })
                }
            },




        }
    },
}
</script>
<style lang="css" scoped src="../css/adminlte.css"></style>