<template>
    <TheContentHeader titlePage="Lista de Roles" />
    <the-content-body>
        <widget-card title="Roles" classHeader="bg-primary">
            <template v-slot:header>
                <div class="d-flex justify-content-between">
                    <div class="text-white">Roles</div>
                    <base-button-fab @on:click="openModal('newRole')" :permissionId="14"></base-button-fab>
                </div>
            </template>
            <template v-slot:body>
                <widget-alerts v-if="alert.showAlert" :messages="alert.messageServer"
                    :typeAlert="alert.typeAlert"></widget-alerts>
                <base-input-group :icon="'fa-solid fa-magnifying-glass'" v-model="search"
                    :onBlur="callLoadRolesIndex"></base-input-group>
                <widget-loading v-if="isLoading.list" />
                <template v-else-if="rolesList.data.length > 0">
                    <widget-select-paginator v-model="numPages"
                        :onChange="callLoadRolesIndex"></widget-select-paginator>
                    <base-table-forms :data="rolesList" :dataFunction="callLoadRolesIndex"
                        :headers="['ID',  'Descripción', 'Acciones']">
                        <tr v-for="role in rolesList.data" :key="role.id">
                            <td class="text-center">{{ role.id }}</td>
                            <td class="text-center" hidden>{{ role.name }}</td>
                            <td class="text-center">{{ role.description }}</td>
                            <td class="text-center">
                                <widget-dropdown icon="fa-solid fa-pen-to-square text-primary" :dropdownItem="[
                        { label: 'Editar', action: () => { openModal(role.id.toString()) }, permissionId: 15, visible: true },
                    ]"></widget-dropdown>
                            </td>
                        </tr>
                    </base-table-forms>
                </template>
                <div v-else>
                    <div class="row justify-content-md-center">
                        <div class="col-12 alert-info text-center mt-5">
                            No hay Información a mostrar
                        </div>
                    </div>
                </div>
            </template>
        </widget-card>
    </the-content-body>
    <!-- Modal Rol -->
    <widget-modal-boostrap modalId="modalRole"
        :modalTitle="roleId === 'newRole' ? 'Crear rol' : 'Actualizar rol ' + role.name"
        modalClassSize="modal-lg modal-dialog-scrollable" :showFooter="false" :modalStatic="'static'">
        <template v-slot:modalBody>
            <widget-loading v-if="isLoading.role" />
            <form v-else @submit.prevent="onSubmit">
                <widget-alerts v-if="alert.showAlert" :messages="alert.messageServer"
                    :typeAlert="alert.typeAlert"></widget-alerts>
                <div class="form-group row">
                    <!-- Nombre Rol -->
                    <div class="col-6">
                        <base-input-forms v-model="name" :errors="alert.errorsFields" title="Nombre Rol"
                            inputId="name"></base-input-forms>
                    </div>
                    <!-- Descripción -->
                    <div class="col-6">
                        <base-input-forms v-model="description" :errors="alert.errorsFields" title="Descripción"
                            inputId="description"></base-input-forms>
                    </div>
                    <div class="col-12">
                        <base-button-forms classButton="btn-primary mt-3" :isLoading="isLoading.operation"
                            :disabled="isLoading.operation" label="Guardar"
                            :permissionId="roleId === 'newRole' ? 14 : 15"></base-button-forms>
                    </div>
                </div>
                <!-- Permisos -->
                <table class="table table-sm table-striped table-hover">
                    <thead>
                        <tr>
                            <th>Módulo</th>
                            <th>Permiso</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(permisos, module) in permissionsList" :key="module">
                            <td>{{ module }}</td>
                            <td>
                                <div v-for="permiso in permisos" :key="permiso.id">
                                    <base-input-check-radio v-model="permissions"
                                        :title="`${permiso.id} ${permiso.description}`" :inputId="'permissions[]'"
                                        typeInput="checkbox" :inputValue="permiso.id"></base-input-check-radio>
                                </div>
                            </td>
                        </tr>
                    </tbody>

                </table>
            </form>
        </template>
    </widget-modal-boostrap>
</template>

<script>
import useRoles from '@/modules/roles/composables/useRoles'
import { useStore } from 'vuex'

import WidgetAlerts from '@/common/widgets/WidgetAlerts.vue';
import BaseButtonFab from '@/common/forms/BaseButtonFab.vue';
import WidgetCard from '@/common/widgets/WidgetCard.vue';
import TheContentBody from '@/modules/dashboard/components/TheContentBody.vue';
import TheContentHeader from '@/modules/dashboard/components/TheContentHeader.vue';
import WidgetDropdown from '@/common/widgets/WidgetDropdown.vue';
import WidgetLoading from '@/common/widgets/WidgetLoading.vue';
import WidgetModalBoostrap from '@/common/widgets/WidgetModalBoostrap.vue';
import BaseTableForms from '@/common/forms/BaseTableForms.vue';
import BaseButtonForms from '@/common/forms/BaseButtonForms.vue';
import BaseInputForms from '@/common/forms/BaseInputForms.vue';
import BaseInputCheckRadio from '@/common/forms/BaseInputCheckRadio.vue';
import BaseInputGroup from '@/common/forms/BaseInputGroup.vue';
import WidgetSelectPaginator from '@/common/widgets/WidgetSelectPaginator.vue';

import { computed, onMounted, ref } from 'vue';
import { Modal } from 'bootstrap';
import { Toast } from "@/helpers/toastSwalert"


export default {

    components: {
        WidgetAlerts,
        WidgetSelectPaginator,
        BaseInputGroup,
        BaseButtonFab,
        WidgetCard,
        TheContentBody,
        TheContentHeader,
        WidgetDropdown,
        WidgetLoading,
        WidgetModalBoostrap,
        BaseTableForms,
        BaseButtonForms,
        BaseInputForms,
        BaseInputCheckRadio
    },

    setup() {

        const roleId = ref()
        const modals = ref({ modalRole: null })
        const numPages = ref("5")
        const search = ref('')
        const store = useStore()
        const {
            loadRolesList,
            rolesList,
            permissionsList,
            alert,
            isLoading,
            getRole,
            createRole,
            updateRole,
            role
        } = useRoles()

        onMounted(() => {
            modals.modalRole = new Modal('#modalRole', {})
        })

        const callLoadRolesIndex = (page) => {
            loadRolesList(page, numPages.value, search.value)
        }
        callLoadRolesIndex()

        const openModal = async (id) => {
            store.commit('roles/clearRole')
            roleId.value = id
            modals.modalRole.show()
            if (id != 'newRole') {
                await getRole(id)
            }
        }
        const closeModal = () => {
            modals.modalRole.hide()
        }
        return {
            rolesList,
            roleId,
            permissionsList,
            alert,
            isLoading,
            role,
            openModal,
            closeModal,
            callLoadRolesIndex,
            numPages,
            search,

            name: computed({
                get: () => store.state['roles']['role']['name'],
                set: (val) => store.commit('roles/updateField', { field: 'name', value: val })
            }),
            description: computed({
                get: () => store.state['roles']['role']['description'],
                set: (val) => store.commit('roles/updateField', { field: 'description', value: val })
            }),
            permissions: computed({
                get: () => store.state['roles']['role']['permissions'],
                set: (val) => store.commit('roles/updateField', { field: 'permissions', value: val })
            }),

            onSubmit: async (e) => {
                const formData = new FormData(e.target)
                if (roleId.value === 'newRole') {
                    const { response } = await createRole(formData)
                    if (response) {
                        openModal(rolesList.value.data[0].id)
                    }
                } else {
                    const { response } = await updateRole(roleId.value, formData)
                }
            },
        }
    }

}
</script>

<style></style>