<template>
    <div class="form-group row">
        <div class="col-12">
            <base-text-area v-model="Ca111RecomendacionAlmacen" textareaId="Ca111RecomendacionAlmacen"
                title="Recomendaciones" :rows="5"></base-text-area>
        </div>

    </div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'



import WidgetLoading from '@/common/widgets/WidgetLoading.vue';
import BaseTextArea from '@/common/forms/BaseTextArea.vue';

import useRequest from '@/modules/credit-request/composables/useRequest'


export default {

    components: {
        WidgetLoading,
        BaseTextArea
    },
    setup() {
        const store = useStore()



        const {
            isLoadingRequest,
            errorsFields,
            requestParameters,
        } = useRequest()




        return {
            errorsFields,
            isLoadingRequest,
            
            Ca111RecomendacionAlmacen: computed({
                get: () => store.state['request']['request']['Ca111RecomendacionAlmacen'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111RecomendacionAlmacen', value: val })
            }),
        }

    },
}
</script>