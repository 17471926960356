<template>
    <div>
        <widget-loading v-if="isLoadingUser" />
        <!-- Crear/Actualizar usuario -->
        <form v-else @submit.prevent="onSubmit">
            <Transition name="fade">
                <widget-alerts v-if="alert.showAlert" :messages="alert.messageServer"
                    :typeAlert="alert.typeAlert"></widget-alerts>
            </Transition>
            <!-- Muestra esto si no esta creando un cliente -->
            <template v-if="!isCreatingUserClient()">
                <div class="form-group row">
                    <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                        <!-- v-if="userDataLoggedIn.perfil === 'admin_shivam' || !isUserEditing" -->
                        <base-select-forms v-model="perfil" idSelect="perfil" title="Perfil/Rol" :options="roles"
                            optionId="name" optionName="description" :errors="alert.errorsFields"></base-select-forms>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                        <base-select-list v-model="tipoIdUsuario" idSelect="tipoIdUsuario" :errors="alert.errorsFields"
                            tipoLista="TPIDEN" listaPadre="TPTERC" listaPadreId="2" title="Tipo ID Usuario"
                            :disabled="isUserEditing"></base-select-list>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                        <base-input-forms v-model="numIdUsuario" inputId="numIdUsuario" :errors="alert.errorsFields"
                            title="Número ID" typeInput="text" typeData="numId"
                            :disabled="isUserEditing"></base-input-forms>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                        <base-input-forms v-model="name" inputId="name" :errors="alert.errorsFields" title="Nombre"
                            typeInput="text" :disabled="isCreatingUserClient()"></base-input-forms>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                        <base-input-forms v-model="username" inputId="username" :errors="alert.errorsFields"
                            title="Nombre usuario" typeInput="text" :disabled="isUserEditing"></base-input-forms>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                        <base-input-forms v-model="alias" inputId="alias" :errors="alert.errorsFields" title="Alias"
                            typeInput="text"></base-input-forms>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                        <base-input-forms v-model="email" inputId="email" :errors="alert.errorsFields" title="E-mail"
                            typeInput="email" :disabled="isUserEditing"></base-input-forms>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                        <base-input-forms v-model="celular" inputId="celular" :errors="alert.errorsFields"
                            title="Celular" typeInput="text" typeData="phone"></base-input-forms>
                    </div>
                </div>
                <!-- Asignar aliado a usuario -->
                <div class="form-group row" v-if="checkPermission(52) && perfil === 'admin_aliado'">
                    <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                        <base-select-list v-model="tipoIdAliado" idSelect="tipoIdAliado" :errors="alert.errorsFields"
                            tipoLista="TPIDEN" listaPadre="" listaHijo="" title="Tipo ID Aliado" :onBlur="searchAliado"
                            :disabled="isSearchingAllied"></base-select-list>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                        <base-input-forms v-model="numIdAliado" inputId="numIdAliado" :errors="alert.errorsFields"
                            title="Número ID Aliado" typeInput="text" typeData="numId" :onBlur="searchAliado"
                            :disabled="isSearchingAllied">
                        </base-input-forms>
                    </div>
                    <div class="col-lg-6 col-md-4 col-sm-6 col-xs-12">
                        <label for="">Nombre Aliado</label>
                        <p v-if="nombreAliado" class="form-control form-control-sm">{{ nombreAliado }}</p>
                        <p v-else-if="isSearchingAllied" class="form-control form-control-sm">Buscando aliado...</p>
                        <p v-else class="form-control form-control-sm">No se ha seleccionado aliado</p>
                    </div>
                </div>
            </template>
            <!-- Muestra esto si esta creando un cliente -->
            <template v-else>
                <div class="form-group row">
                    <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                        <!-- v-if="userDataLoggedIn.perfil === 'admin_shivam' || !isUserEditing" -->
                        <base-select-forms v-model="perfil" idSelect="perfil" title="Perfil/Rol" :options="roles"
                            optionId="name" optionName="name" :errors="alert.errorsFields"></base-select-forms>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                        <base-select-list v-model="tipoIdUsuario" idSelect="tipoIdUsuario" :errors="alert.errorsFields"
                            tipoLista="TPIDEN" listaPadre="TPTERC" listaPadreId="2" title="Tipo ID Usuario"
                            :disabled="isUserEditing"></base-select-list>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                        <base-input-forms v-model="numIdUsuario" inputId="numIdUsuario" :errors="alert.errorsFields"
                            title="Número ID" typeInput="text" typeData="numId"
                            :disabled="isUserEditing"></base-input-forms>
                    </div>
                </div>
            </template>
            <div class="d-flex justify-content-end mt-4 mb-4">
                <base-button-forms classButton="btn-primary" :isLoading="isLoadingOperationUser"
                    :disabled="isLoadingOperationUser" :label="isUserEditing ? 'Actualizar' : 'Guardar'"
                    :permissionId="isUserEditing ? 5 : 4"></base-button-forms>
            </div>
        </form>
        <hr>
        <!-- Asignar almacen a usuario -->
        <user-shop v-if="isUserEditing && checkGroupUser('usuario-almacen', userDataLoggedIn.perfil)" :roles="roles"
            :userId="userId"></user-shop>
    </div>
</template>

<script>
import { ref, watch, reactive, computed, toRef } from 'vue';
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router';

import WidgetAlerts from '@/common/widgets/WidgetAlerts.vue';
import BaseInputForms from '@/common/forms/BaseInputForms.vue';
import BaseSelectForms from '@/common/forms/BaseSelectForms.vue';
import BaseSelectList from '@/common/forms/BaseSelectList.vue';
import BaseButtonForms from '@/common/forms/BaseButtonForms.vue';
import WidgetLoading from '@/common/widgets/WidgetLoading.vue';
import UserShop from '@/modules/users/components/UserShop.vue'
import { Toast } from '@/helpers/toastSwalert'


import useUsers from '../composables/useUsers';
import useAuth from '@/modules/auth/composables/useAuth';
import useAllied from '@/modules/allied/composables/useAllied'



export default {

    props: {

        userId: {
            required: true,

        }

    },

    components: {
        WidgetAlerts,
        BaseButtonForms,
        BaseSelectForms,
        BaseSelectList,
        BaseInputForms,
        WidgetLoading,
        UserShop
    },
    emits: ['on:createdUser', 'on:closeModal'],
    setup(props, { emit }) {
        const store = useStore()
        const {
            createUser,
            userEdit,
            updateUser,
            isLoading,
            isLoadingUser,
            isLoadingOperationUser,
            alert,
        } = useUsers()
        const { checkPermission, getPermissions, userDataLoggedIn, checkGroupUser, } = useAuth()
        const { getInfoAliado } = useAllied()
        const roles = ref(getPermissions('RolUsuario'))
        const buttonDisabled = ref(false)
        const isSearchingAllied = ref(false)
        const isUserEditing = computed(() => props.userId === 'newUser' ? false : true)
        const router = useRouter()


        return {
            buttonDisabled,
            alert,
            isLoading,
            isLoadingUser,
            isLoadingOperationUser,
            isSearchingAllied,
            roles,
            userDataLoggedIn,
            userEdit,
            isUserEditing,


            checkPermission,
            checkGroupUser,
            createUser,
            updateUser,

            isCreatingUserClient: () => {
                return store.state['users']['userEdit']['perfil'] == 'cliente' && !isUserEditing.value
            },

            searchAliado: async () => {
                store.commit('users/updateField', { field: 'nombreAliado', value: '' })
                isSearchingAllied.value = true
                const { response, data } = await getInfoAliado(tipoIdAliado.value, numIdAliado.value)
                if (response) {
                    store.commit('users/updateField', { field: 'nombreAliado', value: data.nombreAliado })
                    isSearchingAllied.value = false
                } else {
                    store.commit('users/updateField', { field: 'nombreAliado', value: '' })
                    isSearchingAllied.value = false
                }

            },
            onSubmit: async (e) => {
                const formData = new FormData(e.target)
                if (!isUserEditing.value) {
                    const { response } = await createUser(formData)
                    if (response) {
                        if (checkPermission(5)) {
                            emit('on:createdUser', userEdit.value.id)
                            return
                        }
                        emit('on:closeModal')
                        // router.push({ name: 'profile', params: { id: userEdit.value.id } })
                    }
                } else {
                    const { response } = await updateUser(formData, userEdit.value.id)
                }
            },


            tipoIdUsuario: computed({
                get: () => store.state['users']['userEdit']['tipoDoc'],
                set: (val) => store.commit('users/updateField', { field: 'tipoDoc', value: val })
            }),
            numIdUsuario: computed({
                get: () => store.state['users']['userEdit']['numeroDoc'],
                set: (val) => store.commit('users/updateField', { field: 'numeroDoc', value: val })
            }),
            name: computed({
                get: () => store.state['users']['userEdit']['name'],
                set: (val) => store.commit('users/updateField', { field: 'name', value: val })
            }),
            celular: computed({
                get: () => store.state['users']['userEdit']['celular'],
                set: (val) => store.commit('users/updateField', { field: 'celular', value: val })
            }),
            email: computed({
                get: () => store.state['users']['userEdit']['email'],
                set: (val) => store.commit('users/updateField', { field: 'email', value: val })
            }),
            username: computed({
                get: () => store.state['users']['userEdit']['username'],
                set: (val) => store.commit('users/updateField', { field: 'username', value: val })
            }),
            alias: computed({
                get: () => store.state['users']['userEdit']['alias'],
                set: (val) => store.commit('users/updateField', { field: 'alias', value: val })
            }),
            perfil: computed({
                get: () => store.state['users']['userEdit']['perfil'],
                set: (val) => store.commit('users/updateField', { field: 'perfil', value: val })
            }),
            tipoIdAliado: computed({
                get: () => store.state['users']['userEdit']['TipoIdentificacionAliado'],
                set: (val) => store.commit('users/updateField', { field: 'TipoIdentificacionAliado', value: val })
            }),
            numIdAliado: computed({
                get: () => store.state['users']['userEdit']['NumIdentificacionAliado'],
                set: (val) => store.commit('users/updateField', { field: 'NumIdentificacionAliado', value: val })
            }),
            nombreAliado: computed({
                get: () => store.state['users']['userEdit']['nombreAliado'],
                set: (val) => store.commit('users/updateField', { field: 'nombreAliado', value: val })
            }),

        }
    },

}

</script>

<style></style>