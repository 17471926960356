<template>
  <TheContentHeader titlePage="Estudio de Crédito" />
  <the-content-body>
    <!-- Bandeja de trabajo -->
    <widget-card title="Solicitudes" classHeader="bg-primary">
      <template v-slot:header>
        <div class="d-flex justify-content-between">
          <div class="text-white">Bandeja de trabajo</div>
        </div>
      </template>
      <template v-slot:body>
        <widget-alerts v-if="alert.showAlert" :messages="alert.messageServer"
          :typeAlert="alert.typeAlert"></widget-alerts>
        <widget-loading v-if="isLoading" />
        <template v-else-if="requestsLists.enGestion.length > 0">
          <!-- Datos estadísticos Solicitudes -->
          <div class="form-group row">
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
              <widget-info-box image="/assets/img/credit-study/Icono_Total_Solicitudes.png" label="Total solicitudes"
                :number="requestsLists.estadisticas.solicitudes_nuevas.length" />
            </div>

            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
              <widget-info-box image="/assets/img/credit-study/Icono_Atendidas.png" label="Atendidas hoy"
                :number="requestsLists.estadisticas.solicitudes_atentidas[0].cantidad_solicitud" />
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
              <widget-info-box image="/assets/img/credit-study/Icono_Promedio_Atencion.png" label="Minutos Prom. Atención"
                :number="requestsLists.estadisticas.promedio_atendidas" />
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
              <widget-info-box icon="fa-solid fa-gears" label="En proceso"
                :number="requestsLists.estadisticas.solicitudes_en_proceso.length" />
            </div>
          </div>
          <div class="form-group row">
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
              <widget-info-box image="/assets/img/credit-study/Icono_Pendiente.png" label="Pendiente Inicio"
                :number="requestsLists.estadisticas.solicitudes_pendientes.length" />
            </div>
            <!-- Información analistas -->
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12"
              v-tippy="getAnalystName(requestsLists.estadisticas.analistas_activos)">
              <widget-info-box image="/assets/img/credit-study/Icono_User.png" label="Analistas activos"
                :number="requestsLists.estadisticas.analistas_activos.length" />
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12"
              v-tippy="getAnalystName(requestsLists.estadisticas.analistas_ausentes)">
              <widget-info-box image="/assets/img/credit-study/Icono_User.png" label="Analistas ausentes"
                :number="requestsLists.estadisticas.analistas_ausentes.length" />
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12"
              v-tippy="getAnalystName(requestsLists.estadisticas.analistas_cerrados)">
              <widget-info-box image="/assets/img/credit-study/Icono_User.png" label="Analistas cerrados"
                :number="requestsLists.estadisticas.analistas_cerrados.length" />
            </div>
          </div>
          <!-- Tabla -->
          <!-- <widget-select-paginator v-model="numPages" :onChange="callLoadRequestsLists"></widget-select-paginator> -->
          <base-table-forms :data="requestsLists.enGestion" :dataFunction="orderList" :headers="headers"
            :whitPagination="false" :orderColumn="column" :order="order" :whitOrder="true">
            <tr v-for="request in requestsLists.enGestion " :key="request.Ca111CodSolicitud">
              <td class="text-center">{{ request.FechaFase1 }}</td>
              <td class="text-center">{{ request.Ca111CodSolicitud }}</td>
              <td class="text-center">{{ requestParameters.estados[request.Ca111EstadoSolicitud][0] }}</td>
              <td class="text-center" v-tippy="request.nombreAnalistaFase1">
                <a href="#" :class="'btn btn-sm ' + requestParameters.fases_estado[request.CodigoEstadoFase1][3]"
                  @click="openModal(request.Ca111CodSolicitud, 1)">
                  {{ requestParameters.fases_estado[request.CodigoEstadoFase1][0] }}
                </a>
              </td>
              <td class="text-center" v-tippy="request.nombreAnalistaFase2">
                <a href="#" :class="'btn btn-sm ' + requestParameters.fases_estado[request.CodigoEstadoFase2][3]"
                  @click="openModal(request.Ca111CodSolicitud, 2)">
                  {{ requestParameters.fases_estado[request.CodigoEstadoFase2][0] }}
                </a>
              </td>
              <td class="text-center" v-tippy="request.nombreAnalistaFase3">
                <a href="#"
                  :class="'btn btn-sm ' + requestParameters.fases_estado[request.CodigoEstadoFase3][3] + ' ' + canEnterFase3(request)"
                  @click="openModal(request.Ca111CodSolicitud, 3)">
                  {{ requestParameters.fases_estado[request.CodigoEstadoFase3][0] }}
                </a>
              </td>
              <td class="text-center">{{ getDiffForHumans(request.FechaFase1) }}</td>
            </tr>
          </base-table-forms>
        </template>
        <div v-else>
          <div class="row justify-content-md-center">
            <div class="col-12 alert-info text-center mt-5">
              No hay Información a mostrar
            </div>
          </div>
        </div>
      </template>
    </widget-card>
  </the-content-body>
  <!-- Modal Fases -->
  <widget-modal-boostrap modalId="modalRequest" :modalTitle="'Fase ' + numeroFase + ' ' + headerRequest"
    modalClassSize="modal-dialog-scrollable modal-xl " :showFooter="false" modalStatic="static">
    <template v-slot:modalBody>
      <fase1-stepper v-if="numeroFase === 1" :requestId="request.Ca111CodSolicitud" @on:closeModal="closeModal"
        @on:LoadRequest="callLoadRequestsLists">
      </fase1-stepper>
      <fase2 v-if="numeroFase === 2" :hideAnswerFase2="hideAnswerFase2" @on:closeModal="closeModal"
        @on:LoadRequest="callLoadRequestsLists"></fase2>
      <fase3 v-if="numeroFase === 3" @on:closeModal="closeModal" @on:LoadRequest="callLoadRequestsLists"></fase3>
    </template>
  </widget-modal-boostrap>
</template>

<script>
import { ref, onMounted, defineAsyncComponent } from 'vue';
import { useStore } from 'vuex'
import { Modal } from 'bootstrap';
import { directive } from 'vue-tippy'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'

import WidgetModalBoostrap from '@/common/widgets/WidgetModalBoostrap.vue'

import useCreditStudy from '@/modules/credit-study/composables/useCreditStudy'
import useFiles from '@/modules/files/composables/useFiles'
import inputMaskFormat from '@/helpers/inputMaskFormat';
import { orderArray } from '@/helpers/utilities'



export default {
  components: {
    WidgetAlerts: defineAsyncComponent(() => import(/*webpackChunkName:"WidgetAlerts"*/'@/common/widgets/WidgetAlerts.vue')),
    WidgetCard: defineAsyncComponent(() => import(/*webpackChunkName:"WidgetCard"*/'@/common/widgets/WidgetCard.vue')),
    TheContentBody: defineAsyncComponent(() => import(/*webpackChunkName:"TheContentBody"*/'@/modules/dashboard/components/TheContentBody.vue')),
    TheContentHeader: defineAsyncComponent(() => import(/*webpackChunkName:"TheContentHeader"*/'@/modules/dashboard/components/TheContentHeader.vue')),
    Fase1Stepper: defineAsyncComponent(() => import(/*webpackChunkName:"Fase1Stepper"*/'@/modules/credit-study/views/Fase1Stepper.vue')),
    Fase2: defineAsyncComponent(() => import(/*webpackChunkName:"Fase2"*/'@/modules/credit-study/views/Fase2.vue')),
    Fase3: defineAsyncComponent(() => import(/*webpackChunkName:"Fase3"*/'@/modules/credit-study/views/Fase3.vue')),
    WidgetInfoBox: defineAsyncComponent(() => import(/*webpackChunkName:"WidgetInfoBox"*/'@/common/widgets/WidgetInfoBox.vue')),
    WidgetLoading: defineAsyncComponent(() => import(/*webpackChunkName:"WidgetLoading"*/'@/common/widgets/WidgetLoading.vue')),
    WidgetModalBoostrap,
    WidgetSelectPaginator: defineAsyncComponent(() => import(/*webpackChunkName:"WidgetSelectPaginator"*/'@/common/widgets/WidgetSelectPaginator.vue')),
    BaseTableForms: defineAsyncComponent(() => import(/*webpackChunkName:"BaseTableForms"*/'@/common/forms/BaseTableForms.vue')),




  },
  directives: {
    tippy: directive,
  },


  setup() {
    const numeroFase = ref(0)
    const hideAnswerFase2 = ref(false)
    const store = useStore()
    const { diffForHumans } = inputMaskFormat()
    const column = ref()
    const order = ref()
    const headers = ref(
      [
        { encabezado: 'Fecha', columna: 'FechaFase1' },
        { encabezado: 'No. Solicitud', columna: 'Ca111CodSolicitud' },
        { encabezado: 'Estado', columna: 'Ca111EstadoSolicitud' },
        { encabezado: 'Fase1', columna: 'CodigoEstadoFase1' },
        { encabezado: 'Fase2', columna: 'CodigoEstadoFase2' },
        { encabezado: 'Fase3', columna: 'CodigoEstadoFase3' },
        { encabezado: 'Tiempo transcurrido', columna: 'FechaFase1' },
      ]
    )
    const {
      alert,
      isLoading,
      request,
      headerRequest,
      loadRequest,
      getRequest,
      requestsLists,
      modals,
      numPages,
      requestParameters,
    } = useCreditStudy()

    const { loadFilesList } = useFiles()



    onMounted(() => {
      modals.modalRequest = new Modal('#modalRequest', {})
    })
    const callLoadRequestsLists = async (page) => {
      loadRequest(page, numPages.value)
    }
    callLoadRequestsLists()

    const orderList = (page, col) => {
      if (col) {
        column.value = col
        order.value = order.value == 'asc' ? 'desc' : 'asc'
      }
      store.state['studyRequest']['requestsLists']['enGestion'] = orderArray(requestsLists.value.enGestion, column.value, order.value)
    }


    return {
      request,
      requestsLists,
      isLoading,
      numPages,
      requestParameters,
      headerRequest,
      numeroFase,
      hideAnswerFase2,
      alert,
      headers,
      orderList,
      column,
      order,

      callLoadRequestsLists,
      diffForHumans,

      openModal: async (id, fase) => {
        modals.modalRequest.show()
        numeroFase.value = fase

        hideAnswerFase2.value = !hideAnswerFase2.value
        store.commit('studyRequest/clearRequest')
        const { data } = await getRequest(id, fase)
        if (fase === 3) {
          // Obtener listado de archivos
          await loadFilesList({
            tipoModelo: 4,
            codModelo: id,
            tipoAliado: '',
            ocupacionCliente: request.value.Ca111CodOcupacion,
            estudioCategoria: ''
          })
        }

      },

      closeModal: () => {
        modals.modalRequest.hide()
      },

      getDiffForHumans: (date) => {
        const { minuts, hours, days } = diffForHumans(date)

        if (minuts >= 0 && minuts < 60) {
          return `hace ${minuts} min(s).`
        }
        if (minuts > 60 && minuts < 1440) {
          return `hace ${hours} hora(s).`
        }
        return `hace ${days} día(s).`

      },
      getAnalystName: (analystArray) => {
        let name = ''
        for (const analyst in analystArray) {
          name = '\n\n' + name + '\n\n' + analystArray[analyst].alias + '\n\n'
        }
        return name
      },


      canEnterFase3: (request) => {

        const fase1 = request.CodigoEstadoFase1 === 'FP' || request.CodigoEstadoFase1 === 'FN'
        const fase2 = request.CodigoEstadoFase2 === 'FP' || request.CodigoEstadoFase2 === 'FN' || request.CodigoEstadoFase2 === 'ZG'
        const fase3 = request.CodigoEstadoFase3 === 'AB' || request.CodigoEstadoFase3 === 'PE'

        if (fase1 && fase2 && fase3) return ''
        return 'disabled'
      },


    }
  }

}
</script>

<style scoped>
.table a {
  width: 114px;
  color: black
}

.table a:hover {
  filter: saturate(200%);
  font-weight: bold;
}


.pendiente {
  background-color: #ffbfb0
}

.devuelta {
  background-color: #f5fac1;
}

.abierta {
  background-color: #cfffca
}

.fin-positivo {
  background-color: #b2dafa
}

.fin-negativo {
  background-color: #e5dde6
}

.suspendidad {
  background-color: #94918f
}

.zona-gris {
  background-color: #f5e1ce
}
</style>
